<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Order</h1>
        <OrderForm :isInCustomer="false" :viewMode="true" :eventName="'updateOrder'" @updateOrder="updateOrder"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OrderForm from '@/components/sales/OrderForm.vue';
import Common from '@/lib/common';
import Sales from '@/lib/sales';

export default {
  name: 'UpdateOrder',
  components: {
    OrderForm,
  },
  mounted(){
    this.loadOrder();
  },
  methods:{
    async loadOrder(){
      const loginInfo = Common.getLoginInfo();
      try{
        const orderId = this.$route.params.id;
        const order = await Sales.loadOrder(this.apiUrl, orderId, loginInfo);
        const orderItems = [];

        //Rewrite the order item format
        for(let i = 0; i < order['order_items'].length; i++){
          const orderItem = {
            id: order['order_items'][i]['id'],
            orderId: order['order_items'][i]['order_id'],
            itemName: order['order_items'][i]['item_name'],
            itemNumber: order['order_items'][i]['item_number'],
            itemAmount: order['order_items'][i]['item_amount'],
          };
          orderItems.push(orderItem);
        }
        order['order_items'] = orderItems;
        this.setCurrentOrder(order);
      }catch(err){
        console.log(err);
      }
    },
    async updateOrder(form){
      try{
        const orderId = this.$route.params.id;
        const orderInfo = Object.assign({}, form);
        const loginInfo = Common.getLoginInfo();
        const customer = await this.getCustomerByName(form.customerId);
        const customerId = customer.id;

        orderInfo.customerId = customerId;
        const order = await Sales.updateOrder(this.apiUrl, orderId, orderInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    async getCustomerByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'name': memberName,
      };
      const customers = await Sales.searchCustomer(this.apiUrl, searchFields, loginInfo);
      const customer = customers[0];
      return customer;
    },
    ...mapActions(['setCurrentOrder']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
